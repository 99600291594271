import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyAwYJ1_W_YGvppn1hyto2FraQsZjzNqvAw",
	authDomain: "saveytm.firebaseapp.com",
	projectId: "saveytm",
	storageBucket: "saveytm.appspot.com",
	messagingSenderId: "952600443514",
	appId: "1:952600443514:web:c19024af3db24420ba19c3",
};

const app = initializeApp(firebaseConfig);

export default app;
